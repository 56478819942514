<template>
<div class="statis-orders-ranking" id="page">
  <el-row class="sor-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item label="日期" prop="date">
          <!-- <el-date-picker value-format="YYYY-MM-DD" v-model="form.date" :disabled-date="disabledDate" type="date" :default-value="[defaultDate]" :clearable="false" /> -->
          <el-date-picker style="width: 260px;" v-model="dateRange" :clearable="false" value-format="YYYY-MM-DD" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="最小订单数">
          <el-input v-model="form.num" @input="inputChange($event)"></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-select v-model="form.txt_type_code" size="small">
            <el-option v-for="item in deviceTypeOption" :key="item.t_device_type_id" :label="item.txt_type_name" :value="item.txt_type_code" />
          </el-select>
        </el-form-item>
        <el-form-item label="省份" prop="txt_area_code">
          <el-select v-model="form.txt_area_code" size="small">
            <el-option v-for="item in regionOptions" :key="item.t_area_id" :label="item.txt_area_name" :value="item.txt_code" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">加载数据</el-button>
          <el-button type="info" :disabled="(tableData||[]).length==0" @click="exportTo()">导出</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-row>
  <el-table :data="tableData" v-loading="loading">
    <el-table-column prop="dt_date" label="日期" align="center" />
    <el-table-column prop="cnt_orders" sortable label="订单数" align="center" />
    <!-- <el-table-column prop="txt_device_iot" label="IOT" align="center" /> -->
    <el-table-column prop="txt_province_name" label="省份" align="center" />
    <!-- <el-table-column prop="txt_province_partner_name" label="省代" align="center" />
    <el-table-column prop="txt_city_name" label="城市" align="center" />
    <el-table-column prop="txt_city_partner_name" label="市代" align="center" />
    <el-table-column prop="txt_salesman_name" label="业务员" align="center" />
    <el-table-column prop="txt_clinic_agent_name" label="诊所医生" align="center" /> -->
    <el-table-column prop="txt_clinic_name" label="诊所" align="center" width="500" />
  </el-table>
</div>
</template>

<script>
// 统计分析 - 订单排行
import { reactive, computed, toRefs, onMounted, ref } from 'vue';
import { getDeviceType, getAreas, getOrderRankList } from "api/apis.js";
import { exportForOrders } from "assets/js/export";
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const moment = require("moment");
    const state = reactive({
      form: {
        date: "",
        txt_type_code: "",
        txt_area_code: "",
        num: 1
      },
      tempRange: [],
      tableData: [],
      loading: false,
      deviceTypeOption: [],
      regionOptions: [],
      defaultDate: computed(() => moment(new Date()).format('YYYY-MM-DD')),
      endDate: computed(() => {
        if (state.tempRange.length > 0) {
          return state.tempRange[1];
        } else {
          return moment(new Date()).format('YYYY-MM-DD')
        }
      }),
      startDate: computed(() => {
        if (state.tempRange.length > 0) {
          return state.tempRange[0];
        } else {
          return moment(new Date()).format('YYYY-MM-DD')
        }
      })
    });

    // 时间区间
    let dateRange = ref(computed({
      get() { return [state.startDate, state.endDate] },
      set(e) {
        state.tempRange = e;
      }
    }));

    onMounted(() => {
      getAreaOption();
      state.form.date = state.defaultDate;
    })

    // 导出
    const exportTo = () => {
      exportForOrders(state.tableData);
    };

    // 输入框改变事件
    const inputChange = (event) => {
      let target = event.replace(/[^\d]/g, ""); // 清除"数字"以外的字符
				target = target.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      if (target == 0) {
        state.form.num = 1;
      } else {
        state.form.num = target;
      }
    }

    // 获取地区信息
    const getAreaOption = () => {
      getAreas({ parea_code: 0 }).then(response => {
        if (response.code == 200 && response.data) {
          state.regionOptions = response.data;
          if ((state.regionOptions || []).length > 0) {
            state.regionOptions.unshift({ t_area_id: 0, txt_area_name: "全国", txt_code: 0 });
            state.form.txt_area_code = state.regionOptions[0].txt_code;
          }
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getDevices();
      })
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          state.deviceTypeOption = response.data || [];
          if ((state.deviceTypeOption || []).length > 0) {
            state.form.txt_type_code = state.deviceTypeOption[0].txt_type_code;
          }
        } else {
          state.deviceTypeOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      }).finally(() => {
        getList();
      })
    };

    const getList = () => {
      state.loading = true;
      getOrderRankList({
        min_order_count: state.form.num,
        txt_device_type: state.form.txt_type_code.split("-")[0],
        txt_area_code: state.form.txt_area_code,
        dt_from: dateRange.value[0],
        dt_to: dateRange.value[1]
      }).then(response => {
        if (response.code == 200) {
          state.tableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    }

    // 禁止选择的日期
    const disabledDate = (time) => {
      return time.getTime() > Date.now();
    };

    return {
      ...toRefs(state),
      getDevices,
      getAreaOption,
      disabledDate,
      getList,
      exportTo,
      dateRange,
      inputChange
    }
  }
}
</script>

<style lang="scss" scoped>
.statis-orders-ranking {
  .el-table {
    font-size: 20px;
    height: calc(100% - 60px);
  }
}
</style>
